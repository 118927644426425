<template>
    <layout 
        title="Jira" 
        :isLoading="isLoading"
        subtitle="Associações de Perfis Jira"
        titleIcon="rocket"
    >
        <table-default>
            <template #default>
                <div class="dual-combo-container">
                    <!-- Combos de Perfis -->
                    <div class="combo">
                        <h4>Perfis Disponíveis</h4>
                        <select multiple v-model="selectedFromAvailable">
                            <option v-for="item in availableProfiles" :key="item.id" :value="item">
                                {{ item.name }} (ID: {{ item.id }})
                            </option>
                        </select>
                    </div>

                    <div class="buttons">
                        <button @click="moveToSelected" :disabled="selectedFromAvailable.length === 0">→</button>
                        <button @click="moveToAvailable" :disabled="selectedFromSelected.length === 0">←</button>
                    </div>
                    <div class="combo">
                        <h4>Perfis Associados</h4>
                        <select multiple v-model="selectedFromSelected">
                            <option v-for="item in selectedProfiles" :key="item.id" :value="item">
                                {{ item.name }}
                            </option>
                        </select>
                    </div>
                </div>

                <!-- Opções Exclusivas -->
                <div class="radio-group">
                <h4>Selecione uma Opção Jira</h4>
                <div>
                    <label>
                        <input
                            type="radio"
                            name="jira-option"
                            :value="{ name: 'Jira Client', id: 1 }"
                            v-model="selectedJiraOption"
                        />
                        Jira Client
                    </label>
                </div>
                <div>
                    <label>
                        <input
                            type="radio"
                            name="jira-option"
                            :value="{ name: 'Jira Software', id: 2 }"
                            v-model="selectedJiraOption"
                        />
                        Jira Software
                    </label>
                </div>
                <div>
                    <label>
                        <input
                            type="radio"
                            name="jira-option"
                            :value="{ name: 'Jira Management', id: 3 }"
                            v-model="selectedJiraOption"
                        />
                        Jira Management
                    </label>
                </div>
                <!-- Botão de ação -->
                <div class="action-button">
                    <button
                        :disabled="!canAssociateProfiles"
                        @click="associateProfiles"
                    >
                        Associar Perfil
                    </button>
                </div>
                <!-- Botão de desassociar perfil -->
                <div class="action-button">
                    <button
                        :disabled="!canDissociateProfiles"
                        @click="dissociateProfiles"
                    >
                        Desassociar Perfil
                    </button>
                </div>
                <div class="action-button">
                    <button @click="openModal">
                        Visualizar Perfis Jira
                    </button>
                </div>
                <div v-if="showModal" class="modal-overlay">
                    <div class="modal-content">
                        <h3>Perfis Associados ao Jira</h3>
                        <ul>
                            <li v-for="profile in currentProfilesAssociate" :key="profile.id">
                                ID: {{ profile.id }}, Grupo: {{ profile.groupId }}
                            </li>
                        </ul>
                        <button @click="closeModal">Fechar</button>
                    </div>
                </div>
            </div>
            </template>
        </table-default>
    </layout>
</template>


<script>
import { Component, Vue } from 'vue-property-decorator';
import TableDefault from '@/components/Layout/TableDefault.vue';
import services from '@/http';

@Component({
    components: {
        TableDefault,
    },
})
export default class AddPerfil extends Vue {
    showModal = false;
    availableProfiles = [];
    currentProfilesAssociate = [];
    getProfiles = [];
    selectedProfiles = []; // Perfis atualmente na caixa "Perfis Associados"
    selectedFromAvailable = []; // Perfis selecionados na caixa "Perfis Disponíveis"
    selectedFromSelected = []; // Perfis selecionados na caixa "Perfis Associados"
    selectedJiraOption = null; // Opção Jira selecionada
    isLoading = false;

    mounted() {
        this.list();
    }

    // Abre o modal e carrega os perfis
    async openModal() {
        try {
            // Verifica se uma opção Jira foi selecionada
            if (!this.selectedJiraOption) {
                this.$root.flashError("Por favor, selecione uma opção Jira antes de visualizar os perfis.");
                return;
            }
            this.isLoading = true;

            // Chama a função listPerfisJira diretamente
            await this.listPerfisJira();

            // Abre o modal após carregar os perfis
            this.showModal = true;
        } catch (err) {
            console.error('Erro ao carregar perfis:', err);
            this.$root.flashError(err.body?.message || 'Erro ao carregar os perfis.');
        } finally {
            this.isLoading = false;
        }
    }


    // Fecha o modal
    closeModal() {
        this.showModal = false;
    }

    // Carrega os perfis disponíveis
    list() {
        this.isLoading = true;
        services.profile.list()
            .then((res) => {
                this.availableProfiles = res.data.data.map((profile) => ({
                    id: profile.PerfilID,
                    name: profile.Titulo,
                }));
            })
            .catch((err) => {
                this.$root.flashError(err);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    async listPerfisJira() {
        this.isLoading = true;


        // Certifique-se de que selectedJiraOption contém apenas o ID
        const selectedId = typeof this.selectedJiraOption === 'object' ? this.selectedJiraOption.id : this.selectedJiraOption;


        if (!selectedId) {
            this.$root.flashError("Nenhuma opção Jira válida foi selecionada.");
            return;
        }

        // Faz a chamada GET para a API com o ID
        this.$http.get('v1/movida/jira/list/' + selectedId)
        .then((res) => {
            // Verifica se a resposta contém os dados esperados
            if (res.body && res.body.data && res.status && Array.isArray(res.body.data)) {
                this.currentProfilesAssociate = res.body.data.map((profile) => ({
                    id: profile.PerfilId,
                    groupId: profile.GrupoId,
                }));


                this.getProfiles = res.body.data.map((profile) => ({
                    perfilId: profile.PerfilId
                }));


            } else {
                this.flash('danger', 'Resposta inválida recebida do servidor.');
            }
        })
        .catch((err) => {
            this.flash('danger', err.body?.message || 'Erro ao buscar perfis.');
        })
        .finally(() => {
            this.isLoading = false;
        });
    }


    // Move perfis para "Perfis Associados"
    moveToSelected() {
        const items = [...this.selectedFromAvailable]; // Copia os itens selecionados na caixa "Perfis Disponíveis"
        this.selectedProfiles.push(...items); // Adiciona os itens à lista "Perfis Associados"
        this.availableProfiles = this.availableProfiles.filter(
            (item) => !items.includes(item) // Remove os itens da lista "Perfis Disponíveis"
        );

        // Seleciona automaticamente os itens movidos
        this.selectedFromSelected = [...this.selectedProfiles];

        // Limpa a seleção de "Perfis Disponíveis"
        this.selectedFromAvailable = [];
    }


    // Move perfis de volta para "Perfis Disponíveis"
    moveToAvailable() {
        const items = [...this.selectedFromSelected];
        this.availableProfiles.push(...items); // Retorna os itens para "Perfis Disponíveis"
        this.selectedProfiles = this.selectedProfiles.filter(
            (item) => !items.includes(item)
        );

        // Atualiza as seleções
        this.selectedFromSelected = [];
    }

    // Associa os perfis e exibe no console
    async associateProfiles() {
        try {

            // Certifique-se de que selectedJiraOption contém apenas o ID
            const selectedId = typeof this.selectedJiraOption === 'object' ? this.selectedJiraOption.id : this.selectedJiraOption;


            // Faz a chamada para listPerfisJira
            await this.listPerfisJira();


            // Incrementa os perfis associados com os perfis selecionados
            const combinedProfiles = [
                ...this.getProfiles,
                ...this.selectedProfiles.map((profile) => ({
                    perfilId: profile.id,
                })),
            ];


            // Realiza o POST para o endpoint com o payload
            const payload = combinedProfiles;;

            this.$http.post(`v1/movida/jira/show/${selectedId}`, payload);

            // Exibe mensagem de sucesso
            this.$root.flashSuccess('Perfis associados com sucesso!');

        } catch (err) {
            console.error('Erro ao associar perfis:', err);
            this.$root.flashError(err.body?.message || 'Erro ao associar perfis.');
        } finally {
            this.isLoading = false; // Finaliza o estado de carregamento
        }
    }

    async dissociateProfiles() {
        try {

            // Faz a chamada para listPerfisJira
            await this.listPerfisJira();

            // Certifique-se de que selectedJiraOption contém apenas o ID
            const selectedId = typeof this.selectedJiraOption === 'object' ? this.selectedJiraOption.id : this.selectedJiraOption;

            // Remove os perfis selecionados da lista de perfis associados
            const updatedProfiles = this.currentProfilesAssociate.filter(
                (profile) => !this.selectedProfiles.some((selected) => selected.id === profile.id)
            );


            // Realiza o POST para o endpoint com o payload atualizado
            const payload = updatedProfiles.map((profile) => ({
                perfilId: profile.id,
            }));

            this.$http.post(`v1/movida/jira/show/${selectedId}`, payload);

            // Exibe mensagem de sucesso
            this.$root.flashSuccess('Perfis desassociados com sucesso!');
        } catch (err) {
            console.error('Erro ao desassociar perfis:', err);
            this.$root.flashError(err.body?.message || 'Erro ao desassociar perfis.');
        } finally {
            this.isLoading = false; // Finaliza o estado de carregamento
        }
    }


    // Computed property para habilitar o botão
    get canAssociateProfiles() {
        return this.selectedFromSelected.length > 0 && this.selectedJiraOption !== null;
    }

    get canDissociateProfiles() {
        return this.selectedFromSelected.length > 0 && this.selectedJiraOption !== null;
    }
}
</script>



<style scoped>
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center; /* Centraliza verticalmente */
    z-index: 1000; /* Garante que o modal fique acima de outros elementos */
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px; /* Largura do modal */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center; /* Centraliza o texto */
}

.modal-content h3 {
    margin-bottom: 20px;
}

.modal-content button {
    margin-top: 20px;
    padding: 10px 15px;
    background-color: #f47920;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.modal-content button:hover {
    background-color: #d4671a;
}
.dual-combo-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 20px;
  margin-top: 20px;
}
.combo {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.radio-group {
  margin-top: 20px;
  text-align: center;
}
.radio-group h4 {
  margin-bottom: 10px;
}
.radio-group label {
  margin-right: 15px;
}
select {
  width: 400px; 
  height: 300px; 
  border: 1px solid #ccc; 
  border-radius: 4px;
  padding: 5px; /* Adiciona espaçamento interno */
  font-size: 16px; /* Ajusta o tamanho da fonte para legibilidade */
}
.buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
button {
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  background-color: #F47920;;
  color: white;
  cursor: pointer;
}
button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
.action-button {
  display: flex;
  justify-content: flex-end; /* Alinha o botão à direita */
  margin-top: 20px;
}

.action-button button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  background-color: #F47920;
  color: white;
  cursor: pointer;
}

.action-button button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
</style>
